import React, { useEffect, useState } from 'react';

import axios from 'axios';

import Processing from '../../../components/processing';
import Room from './Room';

function Consultation(props) {
    const { arrivedToken } = props.location.state;
    const [state, setState] = useState({
        token: null,
        roomName: ''
    })

    useEffect(() => {
        axios({
            method: 'POST',
            url: '/api/doctor/consultation',
            data: { arrivedToken: arrivedToken }
        })
            .then(res => res.data)
            .then(data => {
                const { accessToken, roomName } = data.result;

                setState({
                    token: accessToken,
                    roomName: roomName
                });
            })
            .catch(err => {
                console.error(err);
                props.history.push({
                    pathname: '/error',
                    state: { isDoctor: true }
                });
            });
    }, []);

    let render;
    if (state.token) {
        render = (
            <Room roomName={state.roomName} token={state.token} />
        );
    } else {
        render = (
            <Processing />
        );
    }

    return render;
}

export default Consultation;