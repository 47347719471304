import QueryString from 'query-string';

function useQueryStrings(search, key) {
    // Read query string params
    const queryStrings = QueryString.parse(search);

    // Convert keys to lowercase
    var queryParams = Object.keys(queryStrings).reduce((key, value) => (key[value.toLowerCase()] = queryStrings[value], key), {});
    
    if (key) {
        // Return specified query string
        return queryParams[key];
    } else {
        // Return all query strings
        return queryParams;
    }
}

export default useQueryStrings;