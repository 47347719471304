function usePreferredDevices() {
    const setCameraPreference = (deviceId) => {
        sessionStorage.setItem('PREFERRED_CAMERA_DEVICE_ID', deviceId);
    }

    const getCameraPreference = () => {
        return sessionStorage.getItem('PREFERRED_CAMERA_DEVICE_ID');
    }

    const setMicrophonePreference = (deviceId) => {
        sessionStorage.setItem('PREFERRED_MICROPHONE_DEVICE_ID', deviceId);
    }

    const getMicrophonePreference = () => {
        return sessionStorage.getItem('PREFERRED_MICROPHONE_DEVICE_ID');
    }

    const setSpeakerPreference = (deviceId) => {
        sessionStorage.setItem('PREFERRED_SPEAKER_DEVICE_ID', deviceId);
    }

    const getSpeakerPreference = () => {
        return sessionStorage.getItem('PREFERRED_SPEAKER_DEVICE_ID');
    }

    return {
        setCameraPreference, setMicrophonePreference, setSpeakerPreference,
        getCameraPreference, getMicrophonePreference, getSpeakerPreference
    };
}

export default usePreferredDevices;