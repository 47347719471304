import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { isSupported } from 'twilio-video';

import { Provider } from 'react-redux';
import store from './redux/store';

import { Routes } from './routes';
import './axios';

import Modal from './components/modal';

import MediaError from './components/mediaError';

function App() {
    const location = useLocation();

    if (!isSupported && location.pathname !== '/supportedBrowsers') {
        return <Redirect to="/supportedBrowsers" />;
    }

    return (
        <Provider store={store}>
            <MediaError>
                <Routes />
                <Modal />
            </MediaError>
        </Provider>
    );
}

export default App;