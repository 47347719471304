export const mediaErrors = {
    NotFoundError: {
        // 'name' is the error name reported by the browser, used to match the error with the object
        name: "NotFoundError",
        // 'messages' are the possible messages associated with this error type
        messages: [
            "1. Permission denied by system",
            "2. The object cannot be found here",
            "3. Requested device not found"
        ],
        // 'causes' are the possible causes of this error type, displayed to user
        causes: [
            "The operating system has blocked the browser from accessing the microphone or camera",
            "The machine does not have any microphone or camera connected to it"
        ],
        // 'solutions' are the possible solutions to fix this error type, displayed to user
        solutions: [
            "Please ensure the microphone and camera is enabled for the browser in the operating system settings",
            "Please ensure at least one microphone and camera is connected"
        ]
    },
    NotAllowedError: {
        name: "NotAllowedError",
        messages: [
            "1. Permission denied",
            "2. Permission dismissed",
            "3. The request is not allowed by the user agent or the platform in the current context",
            "4. The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission"
        ],
        causes: [
            "Permission has been denied to access the microphone or camera, either by clicking the “deny” button on the permission dialog, or by going to the browser settings",
            "Permission has been denied to access the microphone or camera by dismissing the permission dialog"
        ],
        solutions: [
            "Allow access to the microphone and camera in the browser settings and then reload the page",
            "Reload the page and grant permission to access the input device"
        ]
    },
    TypeError: {
        name: "TypeError",
        messages: [
            "1. Cannot read property 'getUserMedia' of undefined",
            "2. navigator.mediaDevices is undefined"
        ],
        causes: [
            "Web page is being served from a non-secure context"
        ],
        solutions: [
            "Web page should be served from a secure context (localhost or https)"
        ]
    },
    NotReadableError: {
        name: "NotReadableError",
        messages: [
            "1. Failed starting capture of a audio track",
            "2. Failed starting capture of a video track",
            "3. Could not start audio source",
            "4. Could not start video source",
            "5. The I/O read operation failed"
        ],
        causes: [
            "The browser could not start media capture with the input device even after the permission was given, probably because another app or tab has reserved the input device"
        ],
        solutions: [
            "Close all other apps and tabs that have reserved the input device and reload the page, or worst case, restart the browser"
        ]
    },
    OverconstrainedError: {
        name: "OverconstrainedError",
        messages: [],
        causes: [
            "The input device could not satisfy the requested media constraints"
        ],
        solutions: [
            //"If this exception was raised due to your app requesting a specific device ID, then most likely the input device is no longer connected to the machine, so your app should request the default input device"
        ]
    }
}

export const twilioConnectionErrors = {
    SignalingConnectionError: {
        name: "SignalingConnectionError",
        code: 53000,
        causes: [
            "Could not establish a connection to cloud communication provider"
        ],
        solutions: [
            "Please ensure you have a stable internet connection"
        ]
    },
    SignalingServerBusyError: {
        name: "SignalingServerBusyError",
        code: 53006,
        causes: [
            "Could not establish a connection to cloud communication provider. Provider is too busy to accept new clients"
        ],
        solutions: [
            "Please try reloading the page"
        ]
    },
    // RoomMaxParticipantsExceededError: {
    //     name: "RoomMaxParticipantsExceededError",
    //     code: 53105,
    //     causes: [
    //         "The Room cannot allow in any more Participants to join"
    //     ],
    //     solutions: [
    //         "Please try reloading the page, if the problem persists please contact support"
    //     ]
    // },
    // RoomNotFoundError: {
    //     name: "RoomNotFoundError",
    //     code: 53106,
    //     causes: [
    //         "The client attempted to connect to a Room that does not exist"
    //     ],
    //     solutions: [
    //         "Please try reloading the page, if the problem persists please contact support"
    //     ]
    // },
    MediaConnectionError: {
        name: "MediaConnectionError",
        code: 53405,
        causes: [
            "Could not establish a connection to cloud communication provider. Failed to establish a media connection with the Room"
        ],
        solutions: [
            "Please ensure you have a stable internet connection",
            "If behind a firewall, then it should allow media traffic to and from Twilio to go through"
        ]
    }
}

export const twilioDisconnectionErrors = {
    SignalingConnectionDisconnectedError: {
        name: "SignalingConnectionDisconnectedError",
        code: 53001,
        causes: [
            "Failed to reconnect to cloud communication provider after a network disruption or handoff"
        ],
        solutions: [
            "Please ensure you have a stable internet connection"
        ]
    },
    SignalingConnectionTimeoutError: {
        name: "SignalingConnectionTimeoutError",
        code: 53002,
        causes: [
            "The connection to the cloud communication service provider failed, or the current session expired"
        ],
        solutions: [
            "Please try reload the page, if the problem persists please contact support"
        ]
    },
    // ParticipantDuplicateIdentityError: {
    //     name: "ParticipantDuplicateIdentityError",
    //     code: 53205,
    //     causes: [
    //         "Another client joined the Room with the same identity"
    //     ],
    //     solutions: [
    //         "Your app should make sure each client creates an AccessToken with a unique identity string"
    //     ]
    // },
    MediaConnectionError: {
        name: "MediaConnectionError",
        code: 53405,
        causes: [
            "Failed to re-establish media connection with the cloud communication provider room after a network disruption or handoff"
        ],
        solutions: [
            "Please ensure you have a stable internet connection",
            "If behind a firewall, then it should allow media traffic to and from Twilio to go through"
        ]
    }
}
