import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@aspnet/signalr';

import Processing from '../../../components/processing';
import { useQueryStrings } from '../../../hooks';
import { Button } from 'react-bootstrap';
import DeviceSetup from '../../../components/deviceSetup/Setup';

import styles from '../scss/Landing.module.scss';

function Landing(props) {
    const { token, hidecamera, qrcode } = useQueryStrings(props.location.search);

    if (hidecamera && hidecamera == 'true') {
        sessionStorage.setItem('hideCamera', true);
    }

    const [displayMode, setDisplayMode] = useState('processing');
    const [prevDisplayMode, setPrevDisplayMode] = useState('');
    const [arrivedToken, setArrivedToken] = useState('');


    useEffect(() => {
        if (qrcode && qrcode == 'true') {
            setDisplayMode('qrcode');
        } else {
            callApi();
        }
    }, [token]);

    function callApi() {
        setDisplayMode('processing');

        axios({
            method: 'POST',
            url: '/api/doctor/landing',
            data: { launchToken: token }
        })
            .then(res => res.data)
            .then(data => {
                setArrivedToken(data.result.token);

                if (data.result.patientArrived) {
                    setDisplayMode('patient-arrived');
                } else {
                    setDisplayMode('patient-not-arrived');

                    // Connect to Signal R Hub here
                    initializeSignalRHub(data.result.token);
                }
            })
            .catch(err => {
                console.error(err);
                props.history.push({
                    pathname: '/error',
                    state: { isDoctor: true }
                });
            });
    }

    async function initializeSignalRHub(arrivedToken) {
        const connection = new HubConnectionBuilder()
            .withUrl(`/hubs/videoconsultations?arrivedToken=${arrivedToken}`)
            .configureLogging(LogLevel.Information)
            .build();

        connection.onclose(async (error) => {
            console.assert(connection.state === HubConnectionState.Disconnected);
            console.log(`SignalR connection closed due to error "${error}". Attempting to restart the connection.`);
            await startSignalRConnection(connection);
        });

        connection.on('NotifyPatientArrived', () => {
            setDisplayMode('patient-arrived');
        });

        await startSignalRConnection(connection);
    }

    async function startSignalRConnection(connection) {
        try {
            await connection.start();
            console.assert(connection.state === HubConnectionState.Connected);
        } catch (error) {
            console.assert(connection.state === HubConnectionState.Disconnected);
            console.log('Error connecting to SignalR hub, retrying...', error);

            setTimeout(() => startSignalRConnection(), 3000);
        }
    }

    function onTestDevicesClicked() {
        setPrevDisplayMode(displayMode);
        setDisplayMode('device-test');
    }

    function onTestDevicesComplete() {
        setDisplayMode(prevDisplayMode);
        setPrevDisplayMode('');
    }

    function onStartVideoClicked() {
        // Redirect to Consultation Page
        props.history.push({
            pathname: '/doctor/consultation',
            state: {
                arrivedToken: arrivedToken
            }
        });
    }

    function renderContent() {
        switch (displayMode) {
            case 'processing': {
                return (
                    <Processing />
                );
            }
            case 'qrcode': {
                return (
                    <React.Fragment>
                        <QRCode value={token} size={170} />
                        <Button variant="link" onClick={callApi}>Continue in Socrates</Button>
                    </React.Fragment>
                );
            }
            case 'device-test': {
                return (
                    <DeviceSetup onComplete={onTestDevicesComplete} />
                )
            }
            case 'patient-arrived': {
                return (
                    <React.Fragment>
                        <p>The patient has arrived</p>
                        <Button variant="teal" size="lg" onClick={onStartVideoClicked}>Start Video</Button>
                    </React.Fragment>
                );
            }
            default: {
                return (
                    <p>The patient has not arrived yet</p>
                );
            }
        }
    }

    function renderTestDevices() {
        switch (displayMode) {
            case 'processing':
            case 'qrcode':
            case 'device-test':
                return null;
            default: {
                return (
                    <Button variant="link" className={styles.link} onClick={onTestDevicesClicked}>Test Devices</Button>
                );
            }
        }
    }

    return (
        <div className={styles.container}>
            {renderContent()}
            {renderTestDevices()}
        </div>
    );
}

export default Landing;