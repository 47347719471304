import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClinicMedical } from '@fortawesome/free-solid-svg-icons';

import styles from './scss/Simple.module.scss';

function Layout(props) {    
    return (
        <React.Fragment>
            <div className={styles.header}>
                {/* <img className={styles.logo} src={process.env.PUBLIC_URL + '/images/socrates_logo.png'} alt="Socrates Logo" /> */}
                <FontAwesomeIcon size="2x" icon={faClinicMedical} className={styles.logo} />
                {props.practice && (
                    <span>{props.practice}</span>
                )}
            </div>
            <div className={styles.container}>
                {props.children}
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        practice: state.common.practice.name
    };
};

export default connect(
    mapStateToProps,
    null
)(Layout);