import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { useConnectedDevices, usePreferredDevices } from '../../../hooks';
import AudioAnalyser from './AudioAnalyser';

import styles from '../scss/Microphone.module.scss';

function MicSetup({ isActive }) {
    const { microphones, getMicByDeviceId } = useConnectedDevices();
    const { setMicrophonePreference } = usePreferredDevices();
    const [audio, setAudio] = useState(null);

    useEffect(() => {
        if (isActive) {
            // start microphone
            getMicrophone();
        } else {
            // stop microphone
            stopMicrophone();
        }
    }, [isActive]);

    async function getMicrophone(deviceId) {
        const audio = await getMicByDeviceId(deviceId);

        setAudio(audio);
    }

    function stopMicrophone() {
        if (audio) {
            audio.getTracks().forEach(track => track.stop());
            setAudio(null);
        }
    }

    async function onMicrophoneChanged(e) {
        e.preventDefault();

        // Stop current microphone
        stopMicrophone();

        // Get the new microphone device id
        const deviceId = e.target.value;

        // Start the new microphone & store the device id
        await getMicrophone(deviceId);
        setMicrophonePreference(deviceId);
    }

    return (
        <React.Fragment>
            <h4 className={styles.header}>Is your microphone working?</h4>
            <div className={styles.wave}>
                {audio ? <AudioAnalyser audio={audio} /> : ''}
            </div>
            <Form>
                <Form.Group>
                    <Form.Label>Your preferred microphone</Form.Label>
                    <Form.Control as="select" onChange={onMicrophoneChanged}>
                        {microphones.map(mic => {
                            return <option key={mic.deviceId} value={mic.deviceId}>{mic.label}</option>;
                        })}
                    </Form.Control>
                </Form.Group>
            </Form>
        </React.Fragment>
    );
}

export default MicSetup;