import React from 'react';

import Room from './Room';

function Consultation(props) {
    const { accessToken, roomName } = props.location.state;

    return (
        <Room roomName={roomName} token={accessToken} />
    );
}

export default Consultation;