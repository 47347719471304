import React, { useState, useEffect } from 'react';
import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@aspnet/signalr';
import { Button } from 'react-bootstrap';
import axios from 'axios';

import Processing from '../../../components/processing';
import styles from '../scss/Waiting.module.scss';

function Waiting(props) {
    const arrivedToken = props.match.params.token;
    const [isProcessing, setIsProcessing] = useState(false);
    const [roomProps, setRoomProps] = useState({ doctorArrived: false });

    useEffect(() => {
        callApi();
    }, []);

    async function callApi() {
        await axios({
            method: 'POST',
            url: '/api/patient/consultation',
            data: { arrivedToken: arrivedToken }
        })
            .then(res => res.data)
            .then(data => {
                setIsProcessing(false);

                const { doctorArrived, accessToken, roomName } = data.result;

                setRoomProps({
                    doctorArrived: doctorArrived,
                    accessToken: accessToken,
                    name: roomName
                });

                if (!doctorArrived) {
                    connectToSignalRHub();
                }
            })
            .catch(err => {
                console.error(err);
                setIsProcessing(false);
                props.history.push('/error');
            })
    }

    async function connectToSignalRHub() {
        const connection = new HubConnectionBuilder()
            .withUrl(`/hubs/videoconsultations?arrivedToken=${arrivedToken}`)
            .configureLogging(LogLevel.Trace)
            .build();

        connection.onclose(async (error) => {
            console.assert(connection.state === HubConnectionState.Disconnected);
            console.log(`SignalR connection closed due to error "${error}". Attempting to restart the connection.`);
            await start(connection);
        });

        connection.on('NotifyDoctorArrived', () => {
            callApi();
        });

        await start(connection);
    }

    async function start(connection) {
        try {
            await connection.start();
            console.assert(connection.state === HubConnectionState.Connected);
        } catch (error) {
            console.assert(connection.state === HubConnectionState.Disconnected);
            console.log('Error connecting to SignalR hub, retrying...', error);

            setTimeout(() => start(), 3000);
        }
    }

    function onBeginClicked() {
        // Redirect to Consultation Page
        props.history.push({
            pathname: '/patient/consultation',
            state: {
                accessToken: roomProps.accessToken,
                roomName: roomProps.name
            }
        });
    }

    if (isProcessing) {
        return (
            <Processing />
        );
    } else if (roomProps.doctorArrived) {
        return (
            <div className={styles.container}>
                <p>The doctor will see you now</p>
                <Button variant="teal" size="lg" onClick={onBeginClicked}>Begin Consultation</Button>
            </div>
        );
    } else {
        return (
            <div className={styles.container}>
                <p>The doctor will be with you shortly</p>
            </div>
        );
    }
}

export default Waiting;