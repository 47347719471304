import React from 'react';

import DeviceSetup from '../../../components/deviceSetup/Setup';

function DeviceCheck() {
    return (
        <DeviceSetup />
    );
}

export default DeviceCheck;