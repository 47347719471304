import { combineReducers, createStore } from 'redux';

import common from './common';

const rootReducer = combineReducers({
    common
});

const store = createStore(
    rootReducer
);

export default store;