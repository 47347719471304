import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import styles from '../scss/DeviceError.module.scss';

function DeviceError(props) {
    return (
        <div className={styles.container}>
            <div className={styles.iconContainer}>
                <FontAwesomeIcon size="5x" icon={faExclamationCircle} />
                <h4 className={styles.header}>An error occurred</h4>
            </div>
            <p>We could not access your camera or microphone.</p>
            <p>Please ensure your devices are working. Also check your device settings to ensure this app has permission to access your camera/microphone.</p>
        </div>
    );
}

export default DeviceError;