import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames/bind';

import VideoAvatar from '../../../components/videoAvatar/VideoAvatar';
import { usePreferredDevices } from '../../../hooks';

import styles from './scss/MainVideo.module.scss';
let cx = classnames.bind(styles);

function MainVideo({ participant, roomStatus }) {
    const { getSpeakerPreference } = usePreferredDevices();
    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);
    const videoRef = useRef();
    const audioRef = useRef();

    const [receivingVideo, setReceivingVideo] = useState(false);
    const [receivingAudio, setReceivingAudio] = useState(false);

    const trackpubsToTracks = trackMap => Array.from(trackMap.values())
        .map(publication => publication.track)
        .filter(track => track !== null);

    useEffect(() => {
        if (!participant) {
            return;
        }

        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        const trackSubscribed = track => {
            if (track.kind === 'video') {
                setReceivingVideo(true);
                setVideoTracks(videoTracks => [...videoTracks, track]);
            } else if (track.kind === 'audio') {
                setReceivingAudio(true);
                setAudioTracks(audioTracks => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = track => {
            if (track.kind === 'video') {
                setReceivingVideo(false);
                setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
            } else if (track.kind === 'audio') {
                setReceivingAudio(false);
                setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
            }
        };

        participant.on('trackSubscribed', trackSubscribed);
        participant.on('trackUnsubscribed', trackUnsubscribed);

        const trackEnabled = track => {
            if (track.kind === 'video') {
                // set receiving video
                setReceivingVideo(true);
            } else if (track.kind === 'audio') {
                // set receiving audio
                setReceivingAudio(true);
            }
        };

        const trackDisabled = track => {
            if (track.kind === 'video') {
                // set not recieving video
                setReceivingVideo(false);
            } else if (track.kind === 'audio') {
                // set not receiving audio
                setReceivingAudio(false);
            }
        };

        participant.on('trackEnabled', trackEnabled);
        participant.on('trackDisabled', trackDisabled);

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        }
    }, [participant]);

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            videoTrack.attach(videoRef.current);

            return () => {
                videoTrack.detach();
            }
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);

            // Check for a preferred speaker
            if (getSpeakerPreference()) {
                audioRef.current.setSinkId(getSpeakerPreference());
            }

            return () => {
                audioTrack.detach();
            }
        }
    }, [audioTracks]);

    return (
        <div className={styles.mainVideo}>
            {!receivingVideo && (
                <VideoAvatar isDoctor status={roomStatus} name={participant ? participant.identity : ''} />
            )}
            {/* {!state.receivingAudio && (
                <p>User has they're microphone muted</p>
            )} */}

            {participant && (
                <React.Fragment>
                    <video ref={videoRef} autoPlay className={cx({ mainVideoHidden: !receivingVideo })} />
                    <audio ref={audioRef} autoPlay />
                </React.Fragment>
            )}
        </div>
    );
}

export default MainVideo;