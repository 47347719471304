import React from 'react';
import { Button } from 'react-bootstrap';

import styles from './scss/Nav.module.scss';

function Nav({ currentStep, totalSteps, nextStep, firstStep, onDeviceConfirm, deviceFail }) {
    const showStartBtn = currentStep === 1;
    const showYesNoBtn = currentStep > 1 && currentStep < totalSteps;
    const showRetryBtn = (currentStep === totalSteps) && deviceFail;

    function onYesNoClicked(isWorking) {
        onDeviceConfirm(currentStep, isWorking);
        window.scrollTo(0, 0);
        nextStep();
    }

    return (
        <React.Fragment>
            <div className={styles.nav}>
                {showStartBtn && (
                    <Button
                        variant="teal"
                        className={styles.btn}
                        onClick={nextStep}
                    >
                        Start
                    </Button>
                )}
                {showYesNoBtn && (
                    <React.Fragment>
                        <Button
                            variant="danger"
                            className={styles.btn}
                            onClick={() => onYesNoClicked(false)}
                        >
                            No
                        </Button>
                        <Button
                            variant="teal"
                            className={styles.btn}
                            onClick={() => onYesNoClicked(true)}
                        >
                            Yes
                        </Button>
                    </React.Fragment>
                )}
                {showRetryBtn && (
                    <Button
                        variant="teal"
                        className={styles.btn}
                        onClick={firstStep}>
                        Retry
                    </Button>
                )}
            </div>
        </React.Fragment>
    );
}

export default Nav;