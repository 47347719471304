import React from 'react';

import styles from './scss/Start.module.scss';

function Start() {
    return (
        <h3 className={styles.header}>Lets check your devices</h3>
    );
}

export default Start;