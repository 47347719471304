import React from 'react';
import { Switch } from 'react-router';

import RouteWithLayout from './RouteWithLayout';
import { routes } from './routeConfig';

function Routes() {
    return (
        <Switch>
            {routes.map((route, idx) => {
                return (
                    <RouteWithLayout
                        {...route}
                        key={idx}
                    />
                )
            })}
        </Switch>
    );
}

export default Routes;
