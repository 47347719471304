import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import StepWizard from 'react-step-wizard';

import Start from './Start';
import End from './End';
import Camera from './CameraSetup';
import Mic from './MicrophoneSetup/MicSetup';
import Speaker from './SpeakerSetup';
import Nav from './Nav';

import styles from './scss/Setup.module.scss';

function Setup(props) {
    const [deviceFail, setDeviceFail] = useState(true);
    const [deviceCheck, setDeviceCheck] = useState({
        camera: false,
        microphone: false,
        speaker: false
    });

    useEffect(() => {
        // Check if any devices are not working
        setDeviceFail(Object.values(deviceCheck).includes(false));
    }, [deviceCheck]);

    function onStepChange() {
        window.scrollTo(0, 0);
    }

    function onDeviceConfirm(currentStep, isWorking) {
        switch (currentStep) {
            case 2:
                setDeviceCheck({ ...deviceCheck, camera: isWorking });
                break;
            case 3:
                setDeviceCheck({ ...deviceCheck, microphone: isWorking });
                break;
            case 4:
                setDeviceCheck({ ...deviceCheck, speaker: isWorking });
                break;
        }
    }

    function onComplete() {
        if (props.onComplete) {
            props.onComplete();
        }
    }

    return (
        <StepWizard
            nav={<Nav onDeviceConfirm={onDeviceConfirm} deviceFail={deviceFail} />}
            className={styles.wizard}
            onStepChange={onStepChange}
        >
            <Start />
            <Camera />
            <Mic />
            <Speaker />
            <End devices={deviceCheck} onComplete={onComplete} />
        </StepWizard>
    );
}

export default withRouter(Setup);