import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import cx from 'classnames'

import { usePreferredDevices, useTwilio, useComponentDidUpdate } from '../../../hooks';
import styles from './scss/LocalPreview.module.scss';

function LocalPreview({ participant, cameraDirection, muteMicrophone, stopVideo }) {
    const { getCameraPreference } = usePreferredDevices();
    const { createLocalVideoTrack } = useTwilio();
    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);
    const videoRef = useRef();
    const audioRef = useRef();

    useEffect(() => {
        if (!stopVideo) {
            // On mount, if preferred device was selected use it
            // Otherwise use the camera direction
            let videoConstraints = {};

            if (getCameraPreference()) {
                videoConstraints.deviceId = getCameraPreference();
            }
            else {
                videoConstraints.facingMode = cameraDirection;
            }

            publishVideo(videoConstraints);
        }
    }, []);

    const trackpubsToTracks = trackMap => Array.from(trackMap.values())
        .map(publication => publication.track)
        .filter(track => track !== null);

    useEffect(() => {
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        const trackSubscribed = track => {
            if (track.kind === 'video') {
                setVideoTracks(videoTracks => [...videoTracks, track]);
            } else {
                setAudioTracks(audioTracks => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = track => {
            if (track.kind === 'video') {
                setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
            } else {
                setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
            }
        };

        participant.on('trackSubscribed', trackSubscribed);
        participant.on('trackUnsubscribed', trackUnsubscribed);

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        }
    }, [participant]);

    // On Camera Direction reversed
    useComponentDidUpdate(() => {
        publishVideo({ facingMode: cameraDirection });
    }, [cameraDirection]);

    // On Microphone muted/unmuted
    useComponentDidUpdate(() => {
        if (muteMicrophone) {
            participant.audioTracks.forEach(publication => publication.track.disable());
        } else {
            participant.audioTracks.forEach(publication => publication.track.enable());
        }
    }, [muteMicrophone]);

    // On Video started/stopped
    useComponentDidUpdate(() => {
        if (stopVideo) {
            // Stop publishing video track
            participant.videoTracks.forEach(publication => publication.track.disable());
        } else {
            // Start publishing video track
            // Check if a video track was already started
            if (participant.videoTracks.size === 0) {
                // No track was started, start a new track
                publishVideo({ facingMode: cameraDirection });
            } else {
                // Re-enable the published track (basically re-start the video)
                participant.videoTracks.forEach(publication => publication.track.enable());
            }
        }
    }, [stopVideo])

    function publishVideo(constraints) {
        // Create new local video track
        createLocalVideoTrack(constraints).then(track => {
            // Check if track is returned, if null is returned the useTwilio hook will display the error screen
            if (track) {
                // Get currently published tracks
                const tracks = Array.from(participant.videoTracks.values()).map(tp => tp.track);

                // Unpublish the current tracks
                participant.unpublishTracks(tracks);

                // Publish the new track
                participant.publishTrack(track);

                // Set state which handles attaching/detaching of video element
                setVideoTracks([track]);
            }
        });
    }

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            videoTrack.attach(videoRef.current);

            return () => {
                videoTrack.detach();
                videoTrack.stop();
            }
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);

            return () => {
                audioTrack.detach();
                audioTrack.stop();
            }
        }
    }, [audioTracks]);

    return (
        <Draggable>
            <div className={cx(stopVideo ? styles.myVideoHidden : styles.myVideo)}>
                <video ref={videoRef} autoPlay className="localPreview" />
                <audio ref={audioRef} autoPlay />
            </div>
        </Draggable>
    );
}

export default LocalPreview;