function useAppCheck() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Find out the devices
    var isIos = (/iPad|iPhone|iPod/.test(userAgent)),
        isAndroid = !isIos && (/android/i.test(userAgent)),
        isMobile = isIos || isAndroid;

    //console.log(`Is IOS: ${isIos}, Is Android: ${isAndroid}, Is Mobile: ${isMobile}`);

    function launchApp() {
        if (isMobile) {
            const appUrl = window.location.href.replace(window.location.protocol, "cirrusvc:");
            window.location.assign(appUrl);
        }
    }

    // Return:
    // - isMobile: true/false
    // - launchApp function
    return { isMobile, launchApp }
}

export default useAppCheck;