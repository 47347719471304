import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

import styles from './scss/Nav.module.scss';

function Nav({ currentStep, firstStep, previousStep }) {
    const showNav = currentStep > 1;

    function navToPrevious() {
        window.scrollTo(0, 0);
        previousStep();
    }

    function navToFirst() {
        window.scrollTo(0, 0);
        firstStep();
    }

    return (showNav &&
        <div className={styles.nav}>
            <Button
                variant="danger"
                className={styles.btn}
                onClick={navToFirst}
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </Button>
            <Button
                variant="teal"
                className={styles.btn}
                onClick={navToPrevious}
            >
                <FontAwesomeIcon icon={faAngleLeft} />
            </Button>
        </div>
    );
}

export default Nav;