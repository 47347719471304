import React from 'react';

import styles from './scss/Full.module.scss';

function FullScreenLayout(props) {
    return (
        <div className={styles.container}>
            {props.children}
        </div>
    );
}

export default FullScreenLayout;