import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';

import styles from './scss/Month.module.scss';

function Month({ isActive, onCompleted, nextStep }) {
    const months = [
        { name: 'Jan', monthNo: 1 },
        { name: 'Feb', monthNo: 2 },
        { name: 'Mar', monthNo: 3 },
        { name: 'Apr', monthNo: 4 },
        { name: 'May', monthNo: 5 },
        { name: 'Jun', monthNo: 6 },
        { name: 'Jul', monthNo: 7 },
        { name: 'Aug', monthNo: 8 },
        { name: 'Sep', monthNo: 9 },
        { name: 'Oct', monthNo: 10 },
        { name: 'Nov', monthNo: 11 },
        { name: 'Dec', monthNo: 12 },
    ];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    function onMonthSelected(monthNo) {
        onCompleted(monthNo);
        nextStep();
    }

    if (isActive === false) {
        return null;
    }

    return (
        <div className={styles.container}>
            <h5 className={styles.header}>Select the <strong>MONTH</strong> you were born</h5>

            <div className={`${styles.months}`}>
                {months.map(month => (
                    <Button
                        variant="primary"
                        className={styles.month}
                        key={month.monthNo}
                        onClick={() => onMonthSelected(month.monthNo)}
                    >
                        {month.name}
                    </Button>
                ))}
            </div>
        </div>
    );
}

export default Month;