import React from 'react';
import Nav from 'react-bootstrap/Nav'

import styles from './scss/SupportedBrowsers.module.scss';

function SupportedBrowsers() {
    return (
        <div className={styles.container}>
            <h4 className={styles.header}>Update Your Browser</h4>
            <p>You are using a web browser we don't support.</p>
            <p>For the best experience, we recommend using the latest version of your preferred browser.</p>
            <p>The following browsers are supported:</p>
            <Nav as="ul" className={styles.browserList}>
                <Nav.Link
                    href="https://www.google.com/chrome/"
                    target="_blank"
                    className={styles.link}
                >
                    Chrome
                </Nav.Link>
                <Nav.Link
                    href="https://www.mozilla.org/en-US/firefox/"
                    target="_blank"
                    className={styles.link}
                >
                    Firefox
                </Nav.Link>
                <Nav.Link
                    href="https://www.apple.com/safari/"
                    target="_blank"
                    className={styles.link}
                >
                    Safari
                </Nav.Link>
            </Nav>
        </div>
    );
}

export default SupportedBrowsers;