import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './scss/Error.module.scss';

function Error({ onRetry }) {
    return (
        <div className={styles.container}>
            <FontAwesomeIcon size="5x" icon={faExclamationCircle} />
            <p>The date of birth you entered does not match our records.</p>
            <p>Click below to try again, otherwise please contact your practice.</p>

            <Button
                variant="teal"
                size="lg"
                onClick={onRetry}>
                Retry
            </Button>
        </div>
    );
}

export default Error;