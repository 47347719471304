import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import { Status as RoomStatuses } from '../../../constants/room';
import { usePreferredDevices, useTwilio } from '../../../hooks';

import styles from './scss/Room.module.scss';

import MainVideo from './MainVideo';
import LocalPreview from './LocalPreview';
import Actions from './Actions';

function Room({ roomName, token, history }) {
    const { getMicrophonePreference } = usePreferredDevices();
    const { createLocalAudioTrack, connect } = useTwilio();
    const [roomStatus, setRoomStatus] = useState(RoomStatuses.connecting);
    const [room, setRoom] = useState(null);

    const publishVideo = sessionStorage.getItem("hideCamera");

    const [roomOptions, setRoomOptions] = useState({
        isCameraFault: false,
        cameraDirection: 'user',
        muteMicrophone: false,
        stopVideo: publishVideo ?? false
    });

    const [remoteParticipant, setRemoteParticipant] = useState(null);

    useEffect(() => {
        // Wire up handlers to disconnect from the room when the tab/browser is being closed
        window.addEventListener('beforeunload', disconnectFromRoom);
        window.addEventListener('pagehide', disconnectFromRoom);

        connectToRoom();

        return () => {
            disconnectFromRoom();
        }
    }, [roomName, token]);

    function connectToRoom() {
        let audioConstraints = {};

        // Check for a preferred microphone
        if (getMicrophonePreference()) {
            audioConstraints.deviceId = getMicrophonePreference();
        }

        createLocalAudioTrack({ audioConstraints })
            .then(track => {
                // Check if track is returned, if null is returned the useTwilio hook will display the error screen
                if (track) {
                    connect(token, [track])
                        .then(room => {
                            // Check if room is returned, if null the useTwilio hook will diplay the error screen
                            if (room) {
                                setRoomStatus(RoomStatuses.connected);
                                setRoom(room);

                                const participantConnected = participant => {
                                    setRoomStatus(RoomStatuses.remoteConnected);
                                    setRemoteParticipant(participant);
                                };

                                room.on('participantConnected', participantConnected);
                                room.on('participantDisconnected', participant => {
                                    setRoomStatus(RoomStatuses.remoteDisconnected);
                                    setRemoteParticipant(null)
                                });
                                room.participants.forEach(participantConnected);
                            }
                        });
                }
            });
    }

    function disconnectFromRoom() {
        setRoom(currentRoom => {
            if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                currentRoom.localParticipant.tracks.forEach(trackPublication => {
                    trackPublication.track.stop();
                });
                currentRoom.disconnect();
                setRemoteParticipant(null);

                return null;
            } else {
                return currentRoom;
            }
        });
        setRoomStatus(RoomStatuses.disconnected);
    }

    function onToggleCameraClicked() {
        const newDirection = roomOptions.cameraDirection === 'user' ? 'environment' : 'user';

        setRoomOptions({
            ...roomOptions,
            cameraDirection: newDirection
        });
    }

    function onToggleMicrophoneClicked(e) {
        // Remove focus from button
        e.target.blur();

        setRoomOptions({
            ...roomOptions,
            muteMicrophone: !roomOptions.muteMicrophone
        });
    }

    function onToggleVideoClicked(e) {
        // Remove focus from button
        e.target.blur();

        setRoomOptions({
            ...roomOptions,
            stopVideo: !roomOptions.stopVideo
        });
    }

    function onCameraFault() {
        setRoomOptions({
            ...roomOptions,
            isCameraFault: true
        });
    }

    function onDisconnectClicked() {
        disconnectFromRoom();
    }

    let content;

    if (roomStatus === RoomStatuses.disconnected) {
        content = (
            <p>The video consultation has ended.</p>
        );
    } else {
        content = (
            <React.Fragment>
                <MainVideo
                    participant={remoteParticipant}
                    roomStatus={roomStatus}
                />
                {room && (
                    <LocalPreview
                        participant={room.localParticipant}
                        cameraDirection={roomOptions.cameraDirection}
                        muteMicrophone={roomOptions.muteMicrophone}
                        stopVideo={roomOptions.stopVideo}
                        onCameraFault={onCameraFault}
                    />
                )}
                <Actions
                    isCameraFault={roomOptions.isCameraFault}
                    isMicrophoneMuted={roomOptions.muteMicrophone}
                    isVideoStopped={roomOptions.stopVideo}
                    onToggleCamera={onToggleCameraClicked}
                    onToggleMicrophone={onToggleMicrophoneClicked}
                    onToggleVideo={onToggleVideoClicked}
                    onDisconnect={onDisconnectClicked}
                />
            </React.Fragment>
        );
    }

    return (
        <div className={styles.container}>
            {content}
        </div>
    );
}

export default withRouter(Room);