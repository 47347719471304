import React, { useState, useEffect } from 'react';
import StepWizard from 'react-step-wizard';
import Moment from 'moment';

import Year from './Year';
import Month from './Month';
import Day from './Day';
import Nav from './Nav';

import styles from './scss/DateOfBirth.module.scss';

function DateOfBirth(props) {
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');

    const [noDays, setNoDays] = useState(0);

    useEffect(() => {
        if (year && month) {
            const days = Moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
            setNoDays(days);
        } else {
            setNoDays(0);
        }
    }, [year, month]);

    function onYearDone(yr) {
        setYear(yr);
    }

    function onMonthDone(mn) {
        setMonth(mn);
    }

    function onDayDone(dy) {        
        const dob = Moment.utc({ years: year, months: (month - 1), days: dy });
        props.onDateEntered(dob.toDate());
    }

    function onStepChange() {
        window.scrollTo(0, 0);
    }

    return (
        <StepWizard nav={<Nav />} className={styles.wizard} onStepChange={onStepChange}>
            <Year onCompleted={onYearDone} />
            <Month onCompleted={onMonthDone} />
            <Day onCompleted={onDayDone} noDays={noDays} />
        </StepWizard>
    );
}

export default DateOfBirth;