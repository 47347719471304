import { useDispatch } from 'react-redux';
import { mediaErrors, twilioConnectionErrors, twilioDisconnectionErrors } from '../constants/errors'

import { raiseError } from '../redux/actions/common';

export function useErrorHanders() {
    const dispatch = useDispatch();

    function handleMediaError(error) {
        console.error('Failed to acquire media:', error.name, error.message);

        let errObj = null;

        switch (error.name) {
            case mediaErrors.NotFoundError.name:
                errObj = mediaErrors.NotFoundError;
                break;
            case mediaErrors.NotAllowedError.name:
                errObj = mediaErrors.NotAllowedError;
                break;
            case mediaErrors.TypeError.name:
                errObj = mediaErrors.TypeError;
                break;
            case mediaErrors.NotReadableError.name:
                errObj = mediaErrors.NotReadableError;
                break;
            case mediaErrors.OverconstrainedError.name:
                errObj = mediaErrors.OverconstrainedError;
                break;
            default:
                errObj = {
                    name: 'An unexpected error occurred',
                    message: error.message,
                    solutions: ['Please try reload the page. If the problem persists please contact support']
                }
                break;
        }

        errObj.message = error.message;
        
        dispatch(raiseError(errObj));
    }

    function handleConnectionError(error) {
        console.error('Failed to connect to Twilio', error.name, error.message, error.code);

        let errObj = null;

        switch (error.code) {
            case twilioConnectionErrors.SignalingConnectionError.code:
                errObj = twilioConnectionErrors.SignalingConnectionError;
                break;
            case twilioConnectionErrors.SignalingServerBusyError.code:
                errObj = twilioConnectionErrors.SignalingServerBusyError;
                break;
            case twilioConnectionErrors.MediaConnectionError.code:
                errObj = twilioConnectionErrors.MediaConnectionError;
                break;
            default:
                errObj = {
                    name: 'An unexpected error occurred',
                    message: error.message,
                    solutions: ['Please try reload the page. If the problem persists please contact support']
                }
                break;
        }
        
        dispatch(raiseError(errObj));
    }

    function handleDisconnectionError(error) {
        console.error('Twilio connection has been disconnected', error.name, error.message);

        let errObj = null;

        switch (error.code) {
            case twilioDisconnectionErrors.SignalingConnectionDisconnectedError.code:
                errObj = twilioDisconnectionErrors.SignalingConnectionDisconnectedError;
                break;
            case twilioDisconnectionErrors.SignalingConnectionTimeoutError.code:
                errObj = twilioDisconnectionErrors.SignalingConnectionTimeoutError;
                break;
            case twilioDisconnectionErrors.MediaConnectionError.code:
                errObj = twilioDisconnectionErrors.MediaConnectionError;
                break;
            default:
                errObj = {
                    name: 'An unexpected error occurred',
                    message: error.message,
                    solutions: ['Please try reload the page. If the problem persists please contact support']
                }
                break;
        }
        
        dispatch(raiseError(errObj));
    }

    return {
        handleMediaError,
        handleConnectionError,
        handleDisconnectionError
    };
}
