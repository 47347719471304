import { Simple, Full } from '../layouts';

import { Home, Error, SupportedBrowsers } from '../containers/home';
import { PatientConfirm, PatientDeviceError, PatientLanding, PatientWaiting, PatientConsultation } from '../containers/patient';
import { DoctorDeviceCheck, DoctorDeviceError, DoctorLanding, DoctorConsultation } from '../containers/doctor';
import { Test } from '../containers/test';

const home = [
    { path: "/", component: Home, exact: true, layout: Simple },
    { path: "/error", component: Error, exact: true, layout: Simple },
    { path: "/supportedBrowsers", component: SupportedBrowsers, exact: true, layout: Simple }
];

const doctor = [
    { path: "/doctor/deviceCheck", component: DoctorDeviceCheck, layout: Simple },
    { path: "/doctor/deviceError", component: DoctorDeviceError, layout: Simple },
    { path: "/doctor/landing", component: DoctorLanding, layout: Simple },
    { path: "/doctor/consultation", component: DoctorConsultation, layout: Full }
];

const patient = [
    { path: "/patient/confirm/:token", component: PatientConfirm, layout: Simple },
    { path: "/patient/deviceError", component: PatientDeviceError, layout: Simple },
    { path: "/patient/landing/:token", component: PatientLanding, layout: Simple },
    { path: "/patient/waiting/:token", component: PatientWaiting, layout: Simple },
    { path: "/patient/consultation", component: PatientConsultation, layout: Full },
];

const test = [
    { path: '/test', component: Test, exact: true, layout: Full }
];

export const routes = [
    ...home,
    ...doctor,
    ...patient,
    ...test
];