import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash, faPhone } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames/bind';

import { useAppCheck } from '../../../hooks';
import styles from './scss/Actions.module.scss';
let cx = classnames.bind(styles);

function Actions({ isMicrophoneMuted, isVideoStopped, onToggleCamera, onToggleMicrophone, onToggleVideo, onDisconnect }) {
    const { isMobile } = useAppCheck();

    return (
        <div className={styles.actionsContainer}>
            {isMobile && (
                <Button variant="dark" disabled={isVideoStopped} className={styles.actionButtons} onClick={onToggleCamera}>
                    <FontAwesomeIcon icon={faSync} />
                </Button>
            )}
            <Button variant="dark" onClick={onToggleVideo} className={cx({ actionButtons: true, actionButtonHighlight: isVideoStopped })}>
                <FontAwesomeIcon icon={isVideoStopped ? faVideoSlash : faVideo} />
            </Button>
            <Button variant="danger" className={styles.actionButtons} onClick={onDisconnect}>
                <FontAwesomeIcon icon={faPhone} className={styles.phoneIcon} />
            </Button>
            <Button variant="dark" onClick={onToggleMicrophone} className={cx({ actionButtons: true, actionButtonHighlight: isMicrophoneMuted })}>
                <FontAwesomeIcon icon={isMicrophoneMuted ? faMicrophoneSlash : faMicrophone} />
            </Button>
        </div>
    );
}

export default Actions;