import React from 'react';
import RotateLoader from 'react-spinners/RotateLoader';

import styles from './scss/Processing.module.scss';

function Processing() {
    return (
        <div className={styles.container}>
            <RotateLoader
                color="#fff"
                size={15}
            />
            <p className={styles.text}>Processing</p>
        </div>
    );
}

export default Processing;