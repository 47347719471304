import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';

import styles from './scss/Day.module.scss';

function Day({ isActive, noDays, onCompleted }) {
    const days = Array.from(Array(noDays).keys()).map(n => ++n);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function onDaySelected(day) {
        onCompleted(day);
    }

    if (isActive === false) {
        return null;
    }

    return (
        <div className={styles.container}>
            <h5 className={styles.header}>Select the <strong>DAY</strong> you were born</h5>

            <div className={`${styles.days}`}>
                {days.map(day => (
                    <Button
                        className={styles.day}
                        key={day}
                        onClick={() => onDaySelected(day)}
                    >
                        {day}
                    </Button>
                ))}
            </div>
        </div>
    );
}

export default Day;