// App Imports
import { SET_PRACTICE_DETAILS, MESSAGE_SHOW, MESSAGE_HIDE, RAISE_ERROR } from '../actions/common';

// Initial State
export const commonInitialState = {
    practice: {
        name: ''
    },
    message: {
        text: null,
        open: false
    },
    error: null
}

// State
export default (state = commonInitialState, action) => {
    switch (action.type) {
        case SET_PRACTICE_DETAILS:
            return {
                ...state,
                practice: {
                    name: action.practice
                }
            };
        case MESSAGE_SHOW:
            return {
                ...state,
                message: {
                    text: action.message,
                    open: true
                }
            };

        case MESSAGE_HIDE:
            return {
                ...state,
                message: {
                    text: null,
                    open: false
                }
            };
        case RAISE_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}