import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { messageHide } from '../../redux/actions/common';

function CustomModal(props) {
    return (
        <React.Fragment>
            <Modal
                show={props.common.message.open}
                onHide={props.messageHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.
                </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.messageHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        common: state.common
    };
};

export default connect(mapStateToProps, { messageHide })(CustomModal);