import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import styles from './scss/Year.module.scss';

function Year({ onCompleted, nextStep }) {
    const [isValid, setIsValid] = useState(false);
    const [year, setYear] = useState('');

    const maxYear = new Date().getFullYear();
    const minYear = maxYear - 150;    

    const yearInput = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let isValid = true;

        if (!year) {
            isValid = false;
        } else if (year.length !== 4) {
            isValid = false;
        } else if (year < minYear) {
            isValid = false;
        } else if (year > maxYear) {
            isValid = false;
        } else {
            isValid = true;
        }

        setIsValid(isValid);
    }, [year]);

    function onSubmit(e) {
        e.preventDefault();
        yearInput.current.blur();

        if (isValid) {
            onCompleted(year);
            nextStep();
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <div className={styles.container}>
                <h5 className={styles.header}>Enter the <strong>YEAR</strong> you were born</h5>

                <div className={`${styles.year} ${styles.input}`}>
                    <input
                        ref={yearInput}
                        type="number"
                        placeholder="YYYY"
                        value={year}
                        min={minYear}
                        max={maxYear}
                        onChange={(e) => {
                            if (e.target.value.length <= 4) {
                                setYear(e.target.value);
                            }
                        }}
                    />
                </div>

                <Button
                    className={styles.btnNext}
                    type="submit"
                    disabled={!isValid}
                    variant="success"
                >
                    <FontAwesomeIcon icon={faCheck} />
                </Button>
            </div>
        </form>
    );
}

export default Year;