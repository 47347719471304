import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { Full } from '../../layouts';
import styles from './scss/MediaError.module.scss';

function MediaError({ children, ...props }) {
    const error = props.error;

    if (error) {
        const message = error.message
            ? (
                <p>
                    Sorry looks like there was a problem. The following error messsage was reported <i>'{error.message}'</i>
                </p>
            )
            : <p>Sorry looks like there was a problem</p>;
            
        return (
            <Full>
                <div className={styles.container}>
                    <div className={styles.iconContainer}>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        <h4 className={styles.header}>An error occurred</h4>
                    </div>
                    {message}
                    {error.causes && (
                        <div>
                            <p><u>Causes</u></p>
                            <ul>
                                {error.causes.map((c, idx) => <li key={idx}>{c}</li>)}
                            </ul>
                        </div>
                    )}
                    {error.solutions && (
                        <div>
                            <p><u>Solutions</u></p>
                            <ul>
                                {error.solutions.map((s, idx) => <li key={idx}>{s}</li>)}
                            </ul>
                        </div>
                    )}
                </div>
            </Full>
        );
    }

    return children;
}


const mapStateToProps = ({ common }) => {
    return {
        error: common.error
    };
};


export default connect(mapStateToProps, null)(MediaError);