import Video from 'twilio-video';

import { useErrorHanders } from '../helpers/errorHandlers';


export default function useTwilio() {
    const { handleMediaError, handleConnectionError, handleDisconnectionError } = useErrorHanders();

    const createLocalAudioTrack = (constraints) => {
        return Video.createLocalAudioTrack(constraints)
            .catch(error => {
                handleMediaError(error);
                return null;
            });
    };

    const createLocalVideoTrack = (constraints) => {
        return Video.createLocalVideoTrack(constraints)
            .catch(error => {
                handleMediaError(error);
                return null;
            });
    };

    const createLocalTracks = (constraints) => {
        return Video.createLocalTracks(constraints)
            .catch(error => {
                handleMediaError(error);
                return null;
            });
    }

    const connect = (token, tracks) => {
        return Video.connect(token, { region: 'ie1', tracks: tracks })
            .then(room => {
                room.once('disconnected', (room, error) => {
                    if (error) {
                        handleDisconnectionError(error);
                    }
                });

                return room;
            })
            .catch(error => {
                handleConnectionError(error);
                return null;
            });
    }

    return {
        createLocalAudioTrack,
        createLocalVideoTrack,
        createLocalTracks,
        connect
    };
}