import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { DateOfBirth } from '../../../components/dateOfBirth';
import { useAppCheck } from '../../../hooks';
import Processing from '../../../components/processing';
import DeviceSetup from '../../../components/deviceSetup/Setup';
import Error from './Error';
import { setPracticeDetails } from '../../../redux/actions/common';

import styles from './scss/Landing.module.scss';

function Landing(props) {
    const [state, setState] = useState({
        isProcessing: true,
        isValidToken: false,
        isInvalidDob: false,
        deviceCheckComplete: false
    });
    const { isMobile, launchApp } = useAppCheck();

    const launchToken = props.match.params.token;

    useEffect(() => {
        fetchConsultation();
    }, []);

    async function fetchConsultation() {
        await axios({
            method: 'GET',
            url: '/api/patient',
            params: {
                launchToken: launchToken
            }
        })
            .then(res => res.data)
            .then(data => {
                setState({
                    ...state,
                    isProcessing: false,
                    isValidToken: true
                });
                props.setPracticeDetails(data.result.practice);
            })
            .catch(err => {
                setState({
                    ...state,
                    isProcessing: false
                });
            });
    }

    function onDateEntered(dob) {
        onSubmit(dob);
    }

    async function onSubmit(dob) {
        setState({ ...state, isProcessing: true });

        await axios({
            method: 'POST',
            url: '/api/patient/landing',
            data: {
                launchToken: launchToken,
                dob: dob
            }
        })
            .then(res => res.data)
            .then(data => {
                props.history.push({
                    pathname: `/patient/waiting/${data.result.token}`
                });
            })
            .catch(err => {
                let isDobInvalid = false;

                // Check for invalid DOB error code
                if (err.response.data && err.response.data.responseException) {
                    if (err.response.data.responseException.code === 1) {
                        isDobInvalid = true;
                    }
                }

                setState({ ...state, isProcessing: false, isInvalidDob: isDobInvalid });

                if (!isDobInvalid) {
                    props.history.push('/error');
                }
            });
    }

    if (state.isProcessing) {
        return (
            <Processing />
        );
    } else if (!state.isValidToken) {
        return (
            <h2 className={styles.errorText}>We could not find your consultation</h2>
        );
    } else if (!state.deviceCheckComplete) {
        return (
            <DeviceSetup onComplete={() => setState({ ...state, deviceCheckComplete: true })} />
        );
    } else if (state.isInvalidDob) {
        return (
            <Error onRetry={() => setState({ ...state, isInvalidDob: false })} />
        );
    } else {
        return (
            <DateOfBirth onDateEntered={onDateEntered} />
        );
    }
}

export default connect(
    null,
    { setPracticeDetails }
)(Landing);