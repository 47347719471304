import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './scss/End.module.scss';

function End({ isActive, devices, firstStep, onComplete }) {
    const [displaySummary, setDisplaySummary] = useState(true);

    useEffect(() => {
        if (isActive) {
            // Check if any devices not working
            if (Object.values(devices).includes(false)) {
                setDisplaySummary(true);
            } else {
                setDisplaySummary(false);

                setTimeout(() => {
                    onComplete();
                }, 3000);
            }
        }
    });

    function renderSummary() {
        return (
            <React.Fragment>
                <p>Sorry looks like your devices aren't working, please try another device.</p>

                <div className={styles.deviceSummaryContainer}>
                    {Object.entries(devices).map(([key, val]) => {
                        return (
                            <div key={key} className={styles.deviceSummary}>
                                <p>{key.charAt(0).toUpperCase() + key.slice(1)}</p>
                                <Badge variant={val ? 'success' : 'danger'} className={styles.icon}>
                                    <FontAwesomeIcon icon={val ? faCheck : faTimes} />
                                </Badge>
                            </div>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }

    function renderAllSetUp() {
        return (            
            <h3 className={styles.successText}>You're all set up</h3>
        );
    }

    return displaySummary ? renderSummary() : renderAllSetUp();
}

export default End;