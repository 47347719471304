import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import Processing from '../../../components/processing';
import DeviceSetup from '../../../components/deviceSetup/Setup';
import { setPracticeDetails } from '../../../redux/actions/common';

import styles from '../scss/Confirm.module.scss';

function Confirm(props) {
    const [state, setState] = useState({
        isProcessing: true,
        isValidToken: false,
        deviceCheckComplete: false
    });

    const launchToken = props.match.params.token;

    useEffect(() => {
        confirmConsultation();
    }, []);

    async function confirmConsultation() {
        await axios({
            method: 'PUT',
            url: '/api/patient/confirm',
            params: {
                launchToken: launchToken
            }
        })
            .then(res => res.data)
            .then(data => {
                setState({
                    ...state,
                    isProcessing: false,
                    isValidToken: true
                });
                props.setPracticeDetails(data.result.practice);
            })
            .catch(err => {
                setState({
                    ...state,
                    isProcessing: false
                });
            });
    }

    let content;

    if (state.isProcessing) {
        content = (
            <Processing />
        );
    } else if (!state.isValidToken) {
        content = (
            <p className={styles.confirmText}>We could not find your consultation</p>
        )
    } else if (!state.deviceCheckComplete) {
        content = (
            <DeviceSetup onComplete={() => setState({ ...state, deviceCheckComplete: true })} />
        );
    } else {
        content = (
            <p className={styles.confirmText}>
                You will receive a reminder email within 30 minutes of your appointment with instructions on how to join your consultation.
                If you have any queries or wish to change / cancel your appointment, please contact the practice directly.
            </p>
        );
    }

    return (
        <div className={styles.container}>
            {content}
        </div>
    );
}

export default connect(
    null,
    { setPracticeDetails }
)(Confirm);