import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserMd } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-spinners/PulseLoader';

import { Status as RoomStatuses } from '../../constants/room';

import styles from './scss/VideoAvatar.module.scss';

function VideoAvatar({ status, isDoctor, name }) {
    const [userName, setUserName] = useState('');

    useEffect(() => {
        if (name) {
            if (isDoctor && !name.toLowerCase().startsWith('dr.')) {
                setUserName(`Dr. ${name}`);
            } else {
                setUserName(name);
            }
        }
    }, [name]);

    function renderText() {
        switch (status) {
            case RoomStatuses.connecting:
                return (
                    <span>
                        Connecting
                        <Loader css="display: inline;" size={3} color="#fff" />
                    </span>
                );
            case RoomStatuses.connected:
                return `Waiting on ${isDoctor ? 'doctor' : 'patient'}`;
            case RoomStatuses.remoteConnected:
                return userName;
            case RoomStatuses.remoteDisconnected:
                return `${userName} has disconnected`;
        }
    }

    return (
        <div className={styles.container}>
            <FontAwesomeIcon size="7x" icon={isDoctor ? faUserMd : faUser} className={styles.icon} />
            <div className={styles.userText}>
                {renderText()}
            </div>
        </div>
    );
}

export default VideoAvatar;