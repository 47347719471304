import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';

import { useConnectedDevices, usePreferredDevices, useTwilio } from '../../hooks';

import styles from './scss/Camera.module.scss';

function CameraSetup({ isActive }) {
    const { cameras } = useConnectedDevices();
    const { setCameraPreference } = usePreferredDevices();
    const { createLocalVideoTrack } = useTwilio();
    const [videoTrack, setVideoTrack] = useState(null);
    const videoRef = useRef();

    useEffect(() => {
        if (isActive) {
            // start camera
            getCamera();
        } else {
            // stop camera
            stopCamera();
        }
    }, [isActive]);

    async function getCamera(deviceId) {
        createLocalVideoTrack({
            deviceId: deviceId
        }).then(track => {
            // Check if track is returned, if null is returned the useTwilio hook will display the error screen
            if (track) {
                setVideoTrack(track);
                track.attach(videoRef.current);
            }
        });
    }

    function stopCamera() {
        if (videoTrack) {
            videoTrack.stop();
            videoTrack.detach(videoRef.current);
            setVideoTrack(null);
        }
    }

    async function onSelectedCameraChanged(e) {
        e.preventDefault();

        // Stop current camera
        stopCamera();

        // Get the new camera device id
        const deviceId = e.target.value;

        // Start the new camera & store the device id
        await getCamera(deviceId);
        setCameraPreference(deviceId);
    }

    return (
        <React.Fragment>
            <h4 className={styles.header}>Is your camera working?</h4>
            <video ref={videoRef} className={`${styles.video} localPreview`} />
            <Form>
                <Form.Group>
                    <Form.Label>Your preferred camera</Form.Label>
                    <Form.Control as="select" onChange={onSelectedCameraChanged}>
                        {cameras.map(camera => {
                            return <option key={camera.deviceId} value={camera.deviceId}>{camera.label}</option>;
                        })}
                    </Form.Control>
                </Form.Group>
            </Form>
        </React.Fragment>
    );
}

export default CameraSetup;