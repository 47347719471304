export const SET_PRACTICE_DETAILS = 'SET_PRACTICE_DETAILS';
export const MESSAGE_SHOW = 'COMMON_MESSAGE_SHOW';
export const MESSAGE_HIDE = 'COMMON_MESSAGE_HIDE';
export const RAISE_ERROR = 'RAISE_ERROR'

// Actions
export const setPracticeDetails = (practice) => {
    return {
        type: SET_PRACTICE_DETAILS,
        practice: practice
    };
}
export const messageShow = (message) => {
    return { type: MESSAGE_SHOW, message };
}

export const messageHide = () => {
    return { type: MESSAGE_HIDE };
}

export const raiseError = (error) => {
    return { type: RAISE_ERROR, error };
}