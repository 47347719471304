import { useState, useEffect } from 'react';

import { useErrorHanders } from '../helpers/errorHandlers';

function useConnectedDevices() {
    const [devices, setDevices] = useState([]);

    const { handleMediaError } = useErrorHanders();

    useEffect(() => {
        navigator.mediaDevices.addEventListener('devicechange', getDevices);
        getDevices();

        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', getDevices);
        };
    }, []);

    const getDevices = () => {
        // Check if access is allowed to media devices (camera, microphone & speakers) by calling getUserMedia
        // User will be promted by browser to allow/deny access at this point
        // (we need to call this before enumerateDevices, otherwise the device labels will be empty)
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .then(() => {
                // Get list of all devices
                navigator.mediaDevices.enumerateDevices().then(devices => setDevices(devices));
            })
            .catch(handleMediaError);
    }

    const filter = (type) => devices.filter(device => device.kind === type);
    const cameras = filter('videoinput');
    const microphones = filter('audioinput');
    const speakers = filter('audiooutput');

    const getMicByDeviceId = deviceId => {
        // Get audio stream from a specific input device
        return navigator.mediaDevices.getUserMedia({
            audio: {
                deviceId: deviceId
            }
        }).catch(handleMediaError);
    }

    return {
        cameras,
        microphones,
        speakers,
        getMicByDeviceId
    };
}

export default useConnectedDevices;