import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faSquare } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

import { useConnectedDevices, usePreferredDevices } from '../../hooks';

import styles from './scss/Speaker.module.scss'

function SpeakerSetup({ isActive }) {
    const { speakers } = useConnectedDevices();
    const { setSpeakerPreference } = usePreferredDevices();
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef();

    useEffect(() => {
        if (!isActive && isPlaying) {
            toggleAudio();
        }
    }, [isActive]);

    async function onSelectedSpeakerChanged(e) {
        e.preventDefault();

        // Get the new speaker device id
        const deviceId = e.target.value;

        // Change the output device to the selected speaker
        audioRef.current.setSinkId(deviceId);
        audioRef.current.play();

        // Store the device id
        setSpeakerPreference(deviceId);
    }

    function toggleAudio() {
        isPlaying ? audioRef.current.pause() : audioRef.current.play();
        setIsPlaying(!isPlaying);
    }

    return (
        <React.Fragment>
            <h4 className={styles.header}>Is your speaker working?</h4>
            <audio ref={audioRef} src={process.env.PUBLIC_URL + '/TestAudio.wav'} loop />
            <div>
                <Button
                    onClick={toggleAudio}
                    className={cx(styles.audioBtn, isPlaying ? styles.stopBtn : styles.playBtn)}>
                    <FontAwesomeIcon size="2x" icon={isPlaying ? faSquare : faPlay} className={isPlaying ? styles.stopIcon : styles.playIcon} />
                </Button>
            </div>
            <Form>
                <Form.Group>
                    <Form.Label>Your preferred speaker</Form.Label>
                    <Form.Control as="select" onChange={onSelectedSpeakerChanged}>
                        {speakers.map(speaker => {
                            return <option key={speaker.deviceId} value={speaker.deviceId}>{speaker.label}</option>;
                        })}
                    </Form.Control>
                </Form.Group>
            </Form>
        </React.Fragment>
    );
}

export default SpeakerSetup;