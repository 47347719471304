import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './scss/Error.module.scss';

function Error(props) {
    let isDoctor = false;

    if (props.location.state && props.location.state.isDoctor) {
        isDoctor = props.location.state.isDoctor;
    }

    return (
        <div className={styles.container}>
            <div className={styles.iconContainer}>
                <FontAwesomeIcon size="5x" icon={faExclamationCircle} />
                <h4 className={styles.header}>An error occurred</h4>
            </div>
            <p>We're sorry but we encountered a problem trying to connect you to your consultation.</p>
            {isDoctor
                ? <p>Please contact support</p>
                : <p>Please contact your practice.</p>
            }
        </div>
    );
}

export default Error;